<template>
  <div />
</template>

<script>
  import { personalRepository } from '@/services/repository-factory.js'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'Logout',
    computed: {
      isImpersonating() {
        return !!this.$auth.user?.is_impersonating
      }
    },
    created() {
      if (this.isImpersonating) {
        this.leaveImpersonate()
      } else {
        this.logout()
      }
    },
    methods: {
      async logout() {
        await this.$auth.logout()
        this.$router.push({ name: routeNames.LOGIN })
      },
      async leaveImpersonate() {
        const url = await personalRepository.leaveImpersonate()

        await this.$auth.logout()
        window.location.href = url
      }
    }
  }
</script>
